import {get, post} from "./request";
import axios from "axios";

export const importExcel = (formdata) => {
    return post('tools/import', formdata)
}
export const categoryList = (params) => {
    return get('admin/resourceClassList', params)
}
export const courseList = (params) => {
    return get('admin/courseLists', params)
}

export const uploadFileApi = (form) => {
    return axios.post("file/upload", form, {
        headers: {
            "content-type": "multipart/form-data"
        }
    })
}

export const uploadPdf = (formData, onUploadProgress) => {
    return post("file/upload_file", formData, {
        onUploadProgress,
    })
}

