<template>
    <div class="cm-contain">
        <div class="cm-contain-head">
            <div class="head-title">
                班级管理
            </div>
            <div class="head-botton">
                <el-button @click="downloadTemplate">学生账号模板</el-button>
                <el-button type="primary" @click="createClass">创建班级</el-button>
            </div>
        </div>
        <div class="cm-contain-main">
            <el-table
                    :data="classData"
                    style="width: 100%"
                    :header-cell-style="headStyle"
                    :cell-style="cellStyle">
                <el-table-column
                        prop="c_name"
                        label="班级名称"
                        width="180"
                        align="left">
                </el-table-column>
                <el-table-column
                        prop="s_num"
                        label="班级人数"
                        width="120"
                        align="center">
                </el-table-column>
                <el-table-column
                        width="200"
                        prop="create_time"
                        label="创建时间"
                        align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" @click="importStu(scope.row)">导入学生数据</el-button>
                        <el-button size="small" type="primary" @click="editBtn(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="deleteBtn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="cm-contain-bottom">
            <el-pagination class="pages-right" :current-page.sync="classPages.currentPageNum"
                           :page-size="classPages.eachPageNum" :total="classPages.total"
                           layout="prev, pager, next, jumper" @current-change="classCurrentChange">
            </el-pagination>
        </div>

        <!--添加/编辑弹窗-->
        <el-dialog :title="classTitleType" :visible.sync="dialogAddClass" center width="30%" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addClassForm" ref="addClassForm" :rules="rules" label-position="center" class="info-form">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="c_name">
                    <el-input v-model="addClassForm.c_name" autocomplete="off" placeholder="1-20字符" @keyup.enter.native="addToForm('addClassForm')"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddClass = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addClassForm')">确 定</el-button>
            </div>
        </el-dialog>
        <input type="file" ref="excelFile" name="excel" @change="excelUpload" v-show=false id="">
        <el-dialog title="填写学生账号前缀" :visible.sync="prefixVisiable" width="50%" label-width="100" @close="closePrefix">
            <div class="flex-box">
                <el-form :inline="true" label-position="left" label-width="80px" ref="formPre" :rules="rulesPrefix" :model="formPrefix">
                    <el-form-item label="账号前缀" prop="value">
                        <el-input v-model="formPrefix.value"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitPrex('formPre')">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
    import {importExcel} from "../../utils/apis";
    export default {
        name: "studentManage",
        data() {
            return {
                ////班级列表表单
                addClassForm:{
                    c_id: '',
                    c_name:''
                },
                rules:{
                    c_name: [
                        { required: true, message: '请填写班级名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                },
                //分页
                classPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                //弹窗标题
                classTitleType: '',
                //是否显示弹窗
                dialogAddClass: false,
                //班级列表数据
                classData: [],
                distinguishBtn: '',
                formLabelWidth: '80px',
                currentClassId: null,
                prefixVisiable: false,
                formPrefix: {
                    value: ''
                },
                savePath: '',
                rulesPrefix: {
                    value: [
                        {required: true, message: '请填写账号前缀123', trigger: 'blur'},
                        {min: 1, max: 6, message: '前缀长度在1到6个字符', trigger: 'blur'}
                    ]
                }
            }
        },
        created() {
            this.getClassList();
        },
        methods: {
            closePrefix() {
                this.savePath = "";
            },
            submitPrex(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(this.formPrefix);
                        let formdata = {
                            cid: this.currentClassId, 
                            save_path: this.savePath,
                            prefix_name: this.formPrefix.value
                        };
                        importExcel(formdata).then((res) => {
                            console.log('res', res);
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose: () => {
                                        this.prefixVisiable = false;
                                    }
                                });
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(error => {
                            console.log('err', error);
                        });

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            },
            downloadTemplate() {
                window.open(this.$api.excelTemplateUrl, '_blank');
            },
            excelUpload(evt) {
                console.log(evt);
                let formData = new FormData();
                let file = evt.target.files[0];
                formData.append('excel', file);
                formData.append('cid', this.currentClassId);
                formData.append('tid', parseInt(localStorage.getItem('sId')));
                this.$shttp.axiosFile(this.$api.import, formData, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.getClassList();
                                this.$refs.excelFile.value = '';
                            }
                        });
                    } else {
                        console.log('res', res);
                        this.$message({
                            type: 'error',
                            message: res.msg,
                            duration: 1500,
                            onClose: () => {
                                if (res.data.save_path) {
                                    this.savePath = res.data.save_path;
                                    this.prefixVisiable = true;
                                }
                            }
                        });
                    }
                    console.log(res);
                }, (reject) => {

                });
            },
            //导入学生数据
            importStu(row) {
                this.currentClassId = row.c_id;
                this.$refs.excelFile.click();
            },
            //获取班级列表
            getClassList() {
                let param = {
                    page: this.classPages.currentPageNum,
                    limit: this.classPages.eachPageNum
                }
                this.$shttp.axiosGetBy(this.$api.cl_list, param, (res) => {
                    if (res.code === 200) {
                        this.classData = res.data.data;
                        this.classPages.total = res.data.total;
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            classCurrentChange(val) {
                this.classPages.currentPageNum = val;
                this.getClassList();
            },
            headStyle() {
                return 'background:#F5F5F5;fontWeight:400;fontFamily:MicrosoftYaHei;padding-left:25px;color:#333333'
            },
            cellStyle() {
                return 'padding-left:25px'
            },
            createClass(){
                this.dialogAddClass = !this.dialogAddClass;
                this.classTitleType = '创建班级';
                this.distinguishBtn = 'add';
            },
            //保存
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('c_name', this.addClassForm.c_name);
                            this.$shttp.axiosPost(this.$api.saveClass, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message({
                                        type: 'success',
                                        message: res.msg,
                                        duration: 1000,
                                        onClose: () => {
                                            this.dialogAddClass = !this.dialogAddClass;
                                            this.resetForm();
                                            this.getClassList()
                                        }
                                    });
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.msg,
                                        duration: 1000
                                    });
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                        else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('c_name', this.addClassForm.c_name);
                            formData.append('c_id', this.addClassForm.c_id);
                            this.$shttp.axiosPost(this.$api.saveClass, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message({
                                        type: 'success',
                                        message: res.msg,
                                        duration: 1000,
                                        onClose: () => {
                                            this.dialogAddClass = !this.dialogAddClass;
                                            this.resetForm();
                                            this.getClassList();
                                        }
                                    });
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.msg,
                                        duration: 1000
                                    });
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //编辑
            editBtn(row) {
                this.dialogAddClass = !this.dialogAddClass;
                this.classTitleType = '编辑班级';
                this.distinguishBtn = 'edit';
                this.addClassForm.c_id = row.c_id;
                this.addClassForm.c_name = row.c_name;
            },
            //删除
            deleteBtn(row) {
                this.$confirm('是否删除该班级，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.delClass, {c_id: row.c_id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getClassList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //重置表单
            resetForm() {
                this.$refs.addClassForm.resetFields();
                this.addClassForm = {
                    c_name: ''
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .flex-box {
        &::v-deep {
            .el-input__inner {
                width: 200px;
            }
        }
    }
    .cm-contain {
        height: 100%;
    }

    .cm-contain-head {
        height: 64px;
        line-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
    }

    .head-title {
        margin-left: 40px;
    }

    .head-botton {
        margin-right: 40px;
    }


    .cm-contain-main {
        height: calc(100% - 114px);
        overflow: auto;
    }

    .cm-contain-main span:first-child {
        cursor: pointer;
    }

    .cm-contain-main span:last-child {
        cursor: pointer;
        margin-left: 19px;
    }

    .cm-contain-bottom {
        margin-top: 10px;
        align-items: center;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .el-pagination {
        margin: 0 auto;
    }

    /*start滚动条*/
    ::-webkit-scrollbar {
        width: 0.25rem;
        height: 0.25rem;
        background-image: linear-gradient(135deg, #1DE9B6 0%, rgba(8, 196, 219, 0.5) 72%, rgba(0, 182, 234, 0.3) 100%);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-image: linear-gradient(135deg, #1DE9B6 0%, #08c4db 72%, #057494 100%);
        transition: all .2s;
        border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(95, 95, 95, 0.7);
    }
    /*end滚动条*/
    /*start创建班级*/
    .cm-create-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .cm-create-dialog .create-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .create-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .create-head span:first-child{
        margin-left: 32px;
    }
    .create-head span:last-child {
        margin-right: 16px;
        cursor: pointer;
    }
    /*end创建班级*/
</style>
<style>
    .create-contain .el-input__inner{
        width: 334px;
        height: 30px;
    }
    .cm-create-button{
        padding-bottom: 10px;
    }
    .cm-create-button .el-button{
        height: 32px;
        line-height: 0;
        width: 100px;
    }
</style>